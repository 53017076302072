import { Box } from "grommet";
import React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";

const Homepage = ({ location }) => {
  return (
    <Layout>
      <Seo title="All posts" />
      <Box flex="grow" margin={{ top: "100px" }}></Box>
    </Layout>
  );
};

export default Homepage;
